/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useMutation, useQuery } from 'react-query'

import type {
  SaasAuthMfaActivateDeviceRequest,
  SaasAuthMfaChallengeRequest,
  SaasAuthMfaDeviceResponse,
  SaasAuthMfaDevicesResponse,
  SaasAuthMfaEnabledRequest,
  SaasAuthMfaEnrollDeviceRequest,
  SaasAuthMfaFactorChallengeRequest,
  SaasAuthMfaFactorVerifyChallengeRequest,
  SaasAuthMfaVerifyChallengeRequest,
  SaasAuthResponse,
} from '@modules/cloud-api/v1/types'
import {
  activateSaasCurrentUserMfaDeviceUrl,
  challengeSaasCurrentUserMfaFactorUrl,
  deleteSaasCurrentUserMfaDeviceUrl,
  challengeSaasCurrentUserMfaDeviceUrl,
  enrollSaasCurrentUserMfaDeviceUrl,
  getSaasCurrentUserMfaDevicesUrl,
  verifySaasCurrentUserMfaChallengeUrl,
  setSaasCurrentUserMfaEnabledUrl,
  verifySaasCurrentUserMfaFactorUrl,
} from '@modules/cloud-api/v1/urls'
import { fetchAsJson } from '@modules/query/helpers'
import { queryClient } from '@modules/query'
import type { ApiErrorCollection, QueryHookOptions } from '@modules/query/types'
import type { DeviceType } from '@modules/mfa-management/types'

type MfaPasscodeActivation = Required<Pick<SaasAuthMfaActivateDeviceRequest, 'pass_code'>>
type MfaWebAuthnActivation = Required<Pick<SaasAuthMfaActivateDeviceRequest, 'web_authn'>>
type MfaActivation = MfaPasscodeActivation | MfaWebAuthnActivation

export type MfaPasscodeChallenge = Required<Pick<SaasAuthMfaVerifyChallengeRequest, 'pass_code'>>
export type MfaWebAuthnChallenge = Required<Pick<SaasAuthMfaVerifyChallengeRequest, 'web_authn'>>
export type MfaChallenge = MfaPasscodeChallenge | MfaWebAuthnChallenge

export const useGetSaasCurrentUserMfaDevicesQuery = (options?: QueryHookOptions) =>
  useQuery(
    ['getSaasCurrentUserMfaDevices'],
    () =>
      fetchAsJson<SaasAuthMfaDevicesResponse>(getSaasCurrentUserMfaDevicesUrl(), {
        method: 'get',
      }),
    options,
  )

export const useEnrollSaasCurrentUserMfaDeviceMutation = () =>
  useMutation<SaasAuthMfaDeviceResponse, ApiErrorCollection, { type: DeviceType }>(({ type }) =>
    fetchAsJson<SaasAuthMfaDeviceResponse>(enrollSaasCurrentUserMfaDeviceUrl(), {
      method: 'post',
      body: JSON.stringify(<SaasAuthMfaEnrollDeviceRequest>{ device_type: type }),
    }),
  )

export const useActivateSaasCurrentUserMfaDeviceMutation = () =>
  useMutation<
    SaasAuthMfaDeviceResponse,
    ApiErrorCollection,
    { deviceId: string; activation: MfaActivation }
  >(
    ({ deviceId, activation }) =>
      fetchAsJson<SaasAuthMfaDeviceResponse>(activateSaasCurrentUserMfaDeviceUrl({ deviceId }), {
        method: 'put',
        body: JSON.stringify(activation),
      }),
    { onSuccess: ({ status }) => onDeviceActivationSuccess(status) },
  )

export const useChallengeSaasCurrentUserMfaDeviceMutation = () =>
  useMutation<SaasAuthMfaDeviceResponse, ApiErrorCollection, { deviceId: string; stateId: string }>(
    ({ deviceId, stateId }) =>
      fetchAsJson<SaasAuthMfaDeviceResponse>(challengeSaasCurrentUserMfaDeviceUrl({ deviceId }), {
        method: 'post',
        body: JSON.stringify(<SaasAuthMfaChallengeRequest>{ state_id: stateId }),
      }),
  )

export const useVerifySaasCurrentUserMfaChallengeMutation = () =>
  useMutation<
    SaasAuthResponse,
    ApiErrorCollection,
    { deviceId: string; stateId: string; challenge: MfaChallenge }
  >(({ deviceId, stateId, challenge }) =>
    fetchAsJson<SaasAuthResponse>(verifySaasCurrentUserMfaChallengeUrl({ deviceId }), {
      method: 'post',
      body: JSON.stringify(<SaasAuthMfaVerifyChallengeRequest>{
        state_id: stateId,
        ...challenge,
      }),
    }),
  )

const onDeviceActivationSuccess = (status: SaasAuthMfaDeviceResponse['status']) => {
  queryClient.invalidateQueries('getSaasCurrentUserMfaDevices')

  if (status === 'ACTIVE') {
    fetchAsJson<SaasAuthMfaDeviceResponse>(setSaasCurrentUserMfaEnabledUrl(), {
      method: 'put',
      body: JSON.stringify(<SaasAuthMfaEnabledRequest>{ enabled: true }),
    })
  }
}

export const useChallengeSaasCurrentUserMfaFactorMutation = () =>
  useMutation<unknown, ApiErrorCollection, { deviceId: string }>(({ deviceId }) =>
    fetchAsJson<unknown>(challengeSaasCurrentUserMfaFactorUrl({ deviceId }), {
      method: 'post',
      body: JSON.stringify(<SaasAuthMfaFactorChallengeRequest>{}),
    }),
  )

/**
 * Verifies challenge first then deletes device and invalidates get devices query.
 * The deleted device may not always be the same device that was challenged before,
 * for example: an authenticator device can be removed with a TOTP send to another
 * active email factor.
 */
export const useDeleteSaasCurrentUserMfaDeviceMutation = () =>
  useMutation<
    unknown,
    ApiErrorCollection,
    { deviceId: string; challengedDeviceId: string; passCode: string }
  >(
    ({ deviceId, challengedDeviceId, passCode }) =>
      fetchAsJson<SaasAuthResponse>(
        verifySaasCurrentUserMfaFactorUrl({ deviceId: challengedDeviceId }),
        {
          method: 'post',
          body: JSON.stringify(<SaasAuthMfaFactorVerifyChallengeRequest>{
            pass_code: passCode,
          }),
        },
      ).then(() =>
        fetchAsJson<unknown>(deleteSaasCurrentUserMfaDeviceUrl({ deviceId }), {
          method: 'delete',
        }),
      ),
    { onSuccess: () => queryClient.invalidateQueries('getSaasCurrentUserMfaDevices') },
  )
