/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useMutation } from 'react-query'
import { isEmpty } from 'lodash'

import type {
  SaasAuthMfaChallengeRequest,
  SaasAuthMfaDeviceResponse,
  SaasAuthMfaEnrollDeviceRequest,
  SaasAuthWebAuthnEnrollmentResponse,
} from '@modules/cloud-api/v1/types'
import type { ApiErrorCollection } from '@modules/query/types'
import {
  challengeSaasCurrentUserMfaDeviceUrl,
  enrollSaasCurrentUserMfaDeviceUrl,
} from '@modules/cloud-api/v1/urls'
import { fetchAsJson } from '@modules/query/helpers'

import { useActivateSaasCurrentUserMfaDeviceMutation } from './mfa'

import type { MfaWebAuthnChallenge } from './mfa'

const base64UrlSafeToBase64 = (value: string): string =>
  value.replace(new RegExp('_', 'g'), '/').replace(new RegExp('-', 'g'), '+')

const binaryToString = (value: ArrayBuffer): string =>
  btoa(new Uint8Array(value).reduce((s, byte) => s + String.fromCharCode(byte), ''))

const stringToBinary = (value: string): Uint8Array =>
  Uint8Array.from(atob(base64UrlSafeToBase64(value)), (c) => c.charCodeAt(0))

const createPublicKey = (
  webAuthn: SaasAuthWebAuthnEnrollmentResponse,
): PublicKeyCredentialCreationOptions => {
  const pubKeyCredParams: PublicKeyCredentialParameters[] = webAuthn.pub_key_cred_params.map(
    // TODO: fix webAuthn.pub_key_cred_params.type, which should be fixed to 'public-key'
    ({ alg }) => ({ type: 'public-key', alg }),
  )

  const excludeCredentials = webAuthn.exclude_credentials?.map((credential) => ({
    id: stringToBinary(credential.id),
    // TODO: fix webAuthn.exclude_credentials.transport, which should be an enum
    transports: credential.transports?.map((transport) => transport as AuthenticatorTransport),
    // TODO: fix webAuthn.exclude_credentials.type, which should be fixed to 'public-key'
    type: 'public-key' as PublicKeyCredentialType,
  }))

  return {
    rp: {
      name: webAuthn.rp.name,
    },
    // TODO: fix web_auth.attestation, which should be lowercased
    attestation: 'direct' as AttestationConveyancePreference,
    challenge: stringToBinary(webAuthn.challenge),
    user: {
      ...webAuthn.user,
      id: stringToBinary(webAuthn.user.id),
      displayName: webAuthn.user.display_name,
    },
    pubKeyCredParams,
    authenticatorSelection: {
      userVerification:
        // TODO: webAuthn.authenticator_selection.user_verification should be an enum
        webAuthn.authenticator_selection?.user_verification as UserVerificationRequirement,
      requireResidentKey: webAuthn.authenticator_selection?.require_resident_key,
    },
    excludeCredentials,
  }
}

export const useEnrollWebAuthnMutation = () => {
  const activateMutation = useActivateSaasCurrentUserMfaDeviceMutation()

  const enrollMutation = useMutation<SaasAuthMfaDeviceResponse, ApiErrorCollection | string>(
    () =>
      fetchAsJson<SaasAuthMfaDeviceResponse>(enrollSaasCurrentUserMfaDeviceUrl(), {
        method: 'post',
        body: JSON.stringify(<SaasAuthMfaEnrollDeviceRequest>{ device_type: 'WEBAUTHN' }),
      }),
    {
      onSuccess: async (device) => {
        const { web_authn: webAuthn } = device

        if (!webAuthn) {
          return Promise.reject(
            'WebAuthn enrollment response does not contain the web_authn attribute',
          )
        }

        const newCredential = (await navigator.credentials.create({
          publicKey: createPublicKey(webAuthn),
        })) as PublicKeyCredential | null

        if (newCredential === null) {
          return Promise.reject('Could not create public key')
        }

        const response = newCredential.response as AuthenticatorAttestationResponse
        const attestation = binaryToString(response.attestationObject)
        const clientData = binaryToString(response.clientDataJSON)

        return activateMutation.mutate({
          deviceId: device.device_id,
          activation: { web_authn: { attestation, client_data: clientData } },
        })
      },
    },
  )

  return {
    enrollWebAuthn: enrollMutation.mutate,
    isLoading: enrollMutation.isLoading || activateMutation.isLoading,
    error: enrollMutation.error || activateMutation.error,
  }
}

export const useChallengeWebAuthnMutation = (onSubmit: (challenge: MfaWebAuthnChallenge) => void) =>
  useMutation<
    SaasAuthMfaDeviceResponse,
    ApiErrorCollection | string,
    { deviceId: string; stateId: string }
  >(
    ({ deviceId, stateId }) =>
      fetchAsJson<SaasAuthMfaDeviceResponse>(challengeSaasCurrentUserMfaDeviceUrl({ deviceId }), {
        method: 'post',
        body: JSON.stringify(<SaasAuthMfaChallengeRequest>{ state_id: stateId }),
      }),
    {
      onSuccess: async (device) => {
        const { web_authn: webAuthn } = device

        if (!webAuthn) {
          return Promise.reject(
            'WebAuthn challenge response does not contain the web_authn attribute',
          )
        }

        const assertion = (await navigator.credentials.get({
          publicKey: {
            challenge: stringToBinary(webAuthn.challenge),
          },
        })) as PublicKeyCredential | null

        if (assertion === null) {
          return Promise.reject('Could not get public key')
        }

        const response = assertion.response as AuthenticatorAssertionResponse
        const clientData = binaryToString(response.clientDataJSON)
        const authenticatorData = binaryToString(response.authenticatorData)
        const signatureData = binaryToString(response.signature)

        onSubmit({
          web_authn: {
            client_data: clientData,
            authenticator_data: authenticatorData,
            signature_data: signatureData,
          },
        })
      },
    },
  )

export function parseError(error: ApiErrorCollection | string | null): string | null {
  if (error === null || isEmpty(error)) {
    return null
  }

  if (typeof error === 'string') {
    return error
  }

  if (error.errors[0]) {
    return error.errors[0].message
  }

  return null
}
